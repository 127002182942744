enum Project {
	TINKOFF = 'tinkoff',
	MATCHTV = 'matchtv',
	LIGA = 'liga',
	WINK = 'wink',
	WINKTEST = 'winktest',
	TOTO = 'toto',
}

export {Project};
